import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Carousel from 'react-multi-carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { ReactComponent as Arrow } from '../assets/images/svg/arrow_left.svg';

const Card = ({
  member,
}: {
  member: {
    name: string;
    role: string;
    photo: Promise<typeof import('*.webp')>;
  };
}) => {
  const [image, setImage] = useState('');
  const theme = useTheme();

  useEffect(() => {
    const loadImage = async () => {
      const img = await member.photo;
      setImage(img.default);
    };

    loadImage();
  }, [member]);

  return (
    <Box
      sx={{
        borderRadius: '30px',
        margin: '12px',
        overflow: 'hidden',
        height: '500px',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundColor: 'var(--white)',
        backgroundPosition: 'center 28px',
        backgroundRepeat: 'no-repeat',
        userSelect: 'none',
        [theme.breakpoints.down(1000)]: {
          height: '405px',
        },
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '32px',
            color: 'var(--black)',
            lineHeight: 'normal',
            maxWidth: '200px',
            padding: '50px 50px 15px',
            [theme.breakpoints.down(1000)]: {
              fontSize: '24px',
              maxWidth: '150px',
              padding: '30px 20px 10px',
            },
          }}
        >
          {member.name}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '20px',
            color: 'var(--grey4)',
            lineHeight: 'normal',
            maxWidth: '180px',
            paddingLeft: '50px',
            [theme.breakpoints.down(1000)]: {
              fontSize: '16px',
              paddingLeft: '20px',
              maxWidth: '130px',
            },
          }}
        >
          {member.role}
        </Typography>
      </Box>
    </Box>
  );
};

function MeetOurTeam() {
  const carouselRef = useRef<Carousel>(null);
  const [nextSlide, setNextSlide] = useState(0);
  const theme = useTheme();
  const isLess1000 = useMediaQuery('(max-width:1000px)');
  const isLess675 = useMediaQuery('(max-width:675px)');

  const carouselResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 5000, min: 1000 },
      items: 3,
    },
    // tablet: {
    //   breakpoint: { max: 999, min: 675 },
    //   items: 2,
    //   partialVisibilityGutter: 80,
    // },
    // mobile: {
    //   breakpoint: { max: 674, min: 300 },
    //   items: 1,
    //   partialVisibilityGutter: 50,
    // },
  };

  const TEAM_MEMBERS = [
    {
      name: 'Alexei Kulevets',
      role: 'Co-Founder & CEO',
      photo: import('../assets/images/ourTeam/member_1.webp'),
    },
    {
      name: 'Alexei Porodzinsky',
      role: 'Co-Founder & BizDev Director',
      photo: import('../assets/images/ourTeam/member_2.webp'),
    },
    {
      name: 'Konstantin Kozlovsky',
      role: 'Co-Founder & Product Director',
      photo: import('../assets/images/ourTeam/member_3.webp'),
    },

    {
      name: 'Dzmitry Lubneuski',
      role: 'COO',
      photo: import('../assets/images/ourTeam/member_4.webp'),
    },

    {
      name: 'Mihail Rukavishnikov',
      role: 'CTO',
      photo: import('../assets/images/ourTeam/member_5.webp'),
    },

    {
      name: 'Maxim Sologub',
      role: 'iOS Evangelist',
      photo: import('../assets/images/ourTeam/member_6.webp'),
    },

    {
      name: 'Uladzimir Paulenkovich',
      role: 'Head of Design',
      photo: import('../assets/images/ourTeam/member_7.webp'),
    },

    {
      name: 'Julia Bazhanova',
      role: 'Head of Marketing',
      photo: import('../assets/images/ourTeam/member_8.webp'),
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '1656px',
          }}
        >
          <Box
            sx={{
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                color: 'var(--white)',
                fontSize: '64px',
                lineHeight: 'normal',
                marginTop: '140px',
                marginBottom: '30px',
                [theme.breakpoints.down(1000)]: {
                  fontSize: '36px',
                  marginTop: '80px',
                  marginBottom: '15px',
                },
              }}
            >
              Meet our team
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '30px',
                [theme.breakpoints.down(1000)]: { marginBottom: '0px' },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  color: 'var(--grey4)',
                  fontSize: '24px',
                  lineHeight: 'normal',
                  marginRight: '20px',
                  '& a': {
                    color: 'var(--white)',
                    fontFamily: 'Avenir600',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  },
                  [theme.breakpoints.down(1000)]: { fontSize: '20px' },
                }}
              >
                Brought to you by an experienced team that built the{' '}
                <a href="https://walken.io/" target="_blank" rel="noreferrer">
                  Walken
                </a>{' '}
                game
                {!isLess1000 && <br />} and{' '}
                <a href="https://cuze.io/" target="_blank" rel="noreferrer">
                  Cuze
                </a>{' '}
                eco-system of hybrid casual games with over 4m players since its
                launch in 2022.
              </Typography>
              {!isLess1000 && (
                <Box display={'flex'} gap={'15px'}>
                  <Button
                    disabled={nextSlide === 0}
                    variant="contained"
                    sx={{
                      borderRadius: '50%',
                      width: '66px',
                      height: '66px',
                      backgroundColor: 'var(--white)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        opacity: 0.8,
                        backgroundColor: 'var(--white)',
                      },
                      '&:disabled': {
                        backgroundColor: 'var(--white)',
                      },
                    }}
                    onClick={() => {
                      if (carouselRef && carouselRef.current) {
                        carouselRef.current.goToSlide(nextSlide - 1);
                      }
                    }}
                  >
                    <Arrow></Arrow>
                  </Button>
                  <Button
                    disabled={nextSlide === 5}
                    variant="contained"
                    sx={{
                      transform: 'rotate(180deg)',
                      borderRadius: '50%',
                      width: '66px',
                      height: '66px',
                      backgroundColor: 'var(--white)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        opacity: 0.8,
                        backgroundColor: 'var(--white)',
                      },
                      '&:disabled': {
                        backgroundColor: 'var(--white)',
                      },
                    }}
                    onClick={() => {
                      if (carouselRef && carouselRef.current) {
                        carouselRef.current.goToSlide(nextSlide + 1);
                      }
                    }}
                  >
                    <Arrow></Arrow>
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '0 12px',
            }}
          >
            {isLess1000 ? (
              <Box
                sx={{
                  maxWidth: '100%',
                }}
              >
                <Swiper
                  spaceBetween={5}
                  modules={[Pagination]}
                  slidesPerView={isLess675 ? 1.25 : 2.45}
                >
                  {TEAM_MEMBERS.map((el, index) => (
                    <SwiperSlide key={index}>
                      <Card member={el} key={el.name}></Card>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            ) : (
              <Box
                sx={{
                  maxWidth: '100%',
                  '& .carousel': {
                    overflow: 'visible',
                  },
                }}
              >
                <Carousel
                  className="carousel"
                  partialVisible
                  ref={carouselRef}
                  arrows={false}
                  beforeChange={(nextSlide) => {
                    setNextSlide(nextSlide);
                  }}
                  responsive={carouselResponsive}
                >
                  {TEAM_MEMBERS.map((el, index) => (
                    <Card member={el} key={el.name}></Card>
                  ))}
                </Carousel>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MeetOurTeam;
